<template>
  <main id="app">
    <router-view/>
    <overlay/>
  </main>
</template>
<script>
import overlay from '@/views/elements/Overlay.vue';
export default {
  name: 'App',
  components: {
    overlay
  },
  created: function(){
    console.log(this.$route);
  },
  data: () => ({
  })
}
</script>
<style lang="scss">
  @import './assets/scss/theme.scss';
</style>
