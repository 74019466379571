<template>
  <div class="container">
    <div class="section__head">
      <img src="@/assets/img/Photos_Empanadas_reservation_web-w.jpg" class="img-fluid d-none d-lg-block">
      <img src="@/assets/img/Reservations_Empanadas-w.jpg" class="img-fluid d-block d-lg-none">    </div>
    <div class="section__page">
      <h1>CONTACT</h1>
      <div class="row justify-content-center">
        <!-- <div class="col-lg-5">
          <p>
            <strong>
              RÉSERVATION DE GROUPE<br>
              ET ÉVÉNEMENTS SPÉCIAUX
            </strong><br>
            <br>
            Les empanadas, une solution idéale et conviviale pour les événements et les grandes tablées. Privatisation de notre salle à manger pour: soirée entre amis, soirée d'entreprise, business lunch ou autres réunions.
            <br>
          </p>
        </div> -->
        <div class="col-lg-5">
          <form method="post" @submit.prevent="sendBook" v-if="!loading && !send">
            <label>Nom et Prénom <sup style="color: red;">*</sup></label>
            <input type="text" v-model="booking.name" required class="form-control">
            <label>Tél. <sup style="color: red;">*</sup></label>
            <input type="text" v-model="booking.phone" required class="form-control">
            <label>E-mail <sup style="color: red;">*</sup></label>
            <input type="email" v-model="booking.email" required class="form-control">
            <label>Je suis<sup style="color: red;">*</sup></label><br>
            <input type="radio" name="jesuis" v-model="booking.type" value="privé">&nbsp;<span>Un privé</span><br>
            <input type="radio" name="jesuis" v-model="booking.type" value="professionel">&nbsp;<span>Un professionnel</span><br>
            <label>Sujet<sup style="color: red;">*</sup></label><br>
            <input type="checkbox" v-model="booking.event" value="">&nbsp;<span>Demande de devis pour un évènements</span><br>
            <input type="checkbox" v-model="booking.commande" value="">&nbsp;<span>Commandes d’empanadas à cuire soi même</span><br>
            <input type="checkbox" v-model="booking.autre" value="">&nbsp;<span>Autre questions ou remarque</span><br>
            <label>Message<sup style="color: red;">*</sup></label><br>
            <textarea v-model="booking.message" class="form-control" rows="8" cols="80"></textarea>
            &nbsp;
            <vue-recaptcha
            sitekey="6LdjnBseAAAAAHInDeBUKWKn52JwQ3-aWhyzBOl6"
            :loadRecaptchaScript="true"></vue-recaptcha>
            <br>
            <button v-if="!send" class="btn btn-dark" type="submit" name="button">Envoyer</button>
          </form>
          <p v-if="send">Merci pour votre demande de contact. Notre équipe prendra contact avec vous au plus vite.</p>
          <div class="utils--spacer-double" ></div>
          <p>*Les Empanadas de chez Bondi sont produites à Savigny (Chemin de Geffry 7, 1073 Savigny, Vaud) par la société Fayabash
          Entertainement SARL (Place du Tunnel 1, 1004 Lausanne, Vaud).</p>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {client} from '@/api'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: "book",
  components: {
    VueRecaptcha,
  },
  data: () => ({
    active: false,
    loading: false,
    send: false,
    position: 4,
    booking: {
      name: '',
      phone: '',
      email: '',
      type: 'privé',
      event: 1,
      commande: 0,
      autre: 0,
      message: ''
    },
    msg: {
      message: '',
      'g-recaptcha-response': ''
    },
    errors: 0,
  }),
  methods: {
    sendBook: function(){
      if(
        this.booking.name.length == 0 ||
        this.booking.phone.length == 0 ||
        this.booking.email.length == 0 ||
        this.booking.message.length == 0
      ){
        alert('Erreur, veuillez vérifier les champs du formulaire');
        this.errors = 1
      }else{
        this.errors = 0
      }

      this.msg.message = 'Nouvelle demande'+"\n"+
        'Nom: '+this.booking.name+"\n"+
        'Tél.: '+this.booking.phone+"\n"+
        'Email: '+this.booking.email+"\n"+
        'Je suis: '+"\n"+
        '- ' + this.booking.type + '\n\n'+
        'Sujet: '+"\n"+
        ((this.booking.event) ? '- Évènement\n' : '')+
        ((this.booking.commande) ? '- Commande\n' : '')+
        ((this.booking.autre) ? '- Autre\n' : '') +"\n"+
        'Message: '+this.booking.message

      // g-recaptcha-response
      // eslint-disable-next-line
      let validation = grecaptcha.getResponse()
      let comp = this
      if (validation.length == 0  ) return alert('Veuillez valider le reCAPTCHA!')
      this.msg['g-recaptcha-response'] = validation
      console.log(this.msg);
      console.log(this.booking)
      // send
      this.loading = true
      client.post('https://brasserieduchateau.ch/api/bondi/book', this.msg)
      .then(function(){
        comp.send = true
      })
      .catch(function(error){
        alert(error)
      })
      .finally(() => {
        comp.loading = false
      })


    }
  }

}
</script>
