<template>
  <div class="container">
    <div class="section__head">
      <img src="@/assets/img/Photos_Empanadas_menu_web-w.jpg" class="img-fluid d-none d-lg-block">
      <img src="@/assets/img/Carte_Empanadas-w.jpg" class="img-fluid d-block d-lg-none">
    </div>
    <div class="section__page">
      <h1>About</h1>
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <p>
            Pâte au beurre garnie d’une farce au choix, le tout doucement doré au four. Voilà la base de tout empanada argentine.
            Reconnaissable grâce au motif particulier de son bord festonné, chaque empanada possède son propre caractère : viande
            savoureuse, poisson délicat, fromage filant, ou légumes du soleil. Bondi Empanadas c’est une dizaine de recettes
            intemporelles et authentiques fabriquées à la main et avec des ingrédients locaux.
          </p>
          <p>
            <strong>CATERING / EVENEMENTS</strong>
            <br>
            Les empanadas, une solution idéale et conviviale pour les événements et les grandes tablées (soirée entre amis,
            soirée d'entreprise, festivals ou autres manifestations). Contactez-nous via notre formulaire de contact pour une offre
            personnalisée.
          </p>
          <p>
            <strong>LES EMPANADAS DE CHEZ BONDI A CUIRE SOI MÊME</strong>
            <br>
            Nos empanadas sont également vendu congelée par paquets de 3, 6 ou 12 pièces. Particuliers ou professionnels, vous
            pouvez nous contacter pour toutes commandes via notre formulaires de contact.
          </p>
          <p>
            <strong>LES EMPANADAS DE CHEZ BONDI, CHEZ BONDI</strong>
            <br>
            Venez déguster nos empanadas dans notre local du Flon à Lausanne que nous tenons actuellement en collaboration avec La
            Canoche (Bar à vins naturels).
          </p>
          <p>
            *Les Empanadas de chez Bondi sont produites à Savigny (Chemin de Geffry 7,1073 Savigny, Vaud) par la société Fayabash
            Entertainement SARL (Place du Tunnel 1, 1004 Lausanne, Vaud).
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "practical",
  data: () => ({
  })
}
</script>
